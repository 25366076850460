import React, {
  useRef,
} from 'react'

import PropTypes from 'prop-types'

import { IntlProvider } from '@termly_web/common'

import Termly from 'termly-namespace'

import { TERMLY_CODE_SNIPPET_SUPPORT } from 'constants/domIds'


export default function ResourceBlockerIntlProvider({ children, supportedLocales }) {
  // This was initially defined as a file constant, but the problem is that
  // Termly.apiBase wasn't getting set until after this file was loaded,
  // thus resulting in '/undefined/i18n/:locale.json'. We're using useRef()
  // here instead of useMemo() because the URL is never going to change.
  const {
    current: localeFileURLTemplate,
  } = useRef(`${ Termly.apiBase }/resource-blocker/i18n/:locale.json`)

  const preferredLocales = window.navigator.languages

  const rootElem = document.getElementById(TERMLY_CODE_SNIPPET_SUPPORT)

  return (
    <IntlProvider
      localeFileURLTemplate={ localeFileURLTemplate }
      preferredLocales={ preferredLocales }
      rootElem={ rootElem }
      supportedLocales={ supportedLocales }
    >
      { children }
    </IntlProvider>
  )
}

ResourceBlockerIntlProvider.propTypes = {
  supportedLocales: IntlProvider.propTypes.supportedLocales,

  children: PropTypes.node,
}
