import { useQuery } from '@tanstack/react-query'

import { Client } from '@termly_web/common'

const GET_URL = 'v1/documents/:documentID/preview'


export default function useDocumentPreviewQuery({ documentID, status }, options) {
  const cacheKey = [
    'documentPreview',
    documentID,
    { status },
  ]

  const url = GET_URL.replace(/:documentID/, documentID)

  const {
    data = {},
    ...rest
  } = useQuery(cacheKey,
               () => Client.get(url, { params: { status } }),
               options)

  const { content } = data.data || {}

  return {
    content,
    ...rest,
  }
}
