import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Required from '../Required'

import Styles from './styles.scss'


export default function Label({ children, className = 'label', isRequired, tagName = 'label', text: label }) {
  const Component = tagName

  return (
    <Component className={ className }>
      <div className={ Styles.text }>
        { renderText(label) }
        { renderRequired({ isRequired }) }
      </div>

      { children }
    </Component>
  )
}

Label.propTypes = {
  // TODO: rename to 'label' now that this can be either a node or a message
  // (out of scope for current change).
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
      defaultMessage: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ]).isRequired,

  children: PropTypes.node,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  tagName: PropTypes.string,
}

function renderText(label) {
  if ( label.defaultMessage ) {
    return (
      <FormattedMessage
        { ...label }
      />
    )
  }

  return label
}

function renderRequired({ isRequired }) {
  if ( !isRequired ) {
    return null
  }

  return (
    <Required />
  )
}
