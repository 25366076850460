import React from 'react'
import PropTypes from 'prop-types'
import { usePopperTooltip } from 'react-popper-tooltip'

import Styles from './styles.scss'

import ToolTipIcon from './img/tooltip-icon.svg'

const TRIGGER_CLASS_NAME = `${ Styles.icon } t-trigger`


export default function ToolTip({ children, interactive }) {
  const {
    setTriggerRef,
    ...tooltip
  } = usePopperTooltip({
    interactive,
    offset: [0, 10],
    trigger: 'click',
  })

  return (
    <div className={ Styles.root }>
      <img
        className={ TRIGGER_CLASS_NAME }
        onClick={ onClick }
        ref={ setTriggerRef }
        src={ ToolTipIcon }
      />
      {
        renderTip({
          children,
          tooltip,
        })
      }
    </div>
  )
}

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,

  interactive: PropTypes.bool,
}

function onClick(e) {
  e.stopPropagation()
}

function renderTip({ children, tooltip }) {
  if ( !tooltip.visible ) {
    return null
  }

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef
  } = tooltip

  const containerProps = getTooltipProps({
    className: `${ Styles.container } t-container`,
  })

  const arrowProps = getArrowProps({
    className: `${ Styles.arrow } t-arrow`,
  })

  return (
    <div
      ref={ setTooltipRef }
      { ...containerProps }
    >
      <div { ...arrowProps } />

      { children }
    </div>
  )
}

