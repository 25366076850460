import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import Placeholder from './Placeholder'


export default function PreviewContent({ content, isLoading }) {
  const innerHTML = useMemo(() => ({
    __html: content,
  }), [content])

  if ( isLoading ) {
    return (
      <Placeholder />
    )
  }

  return (
    <div className="previewContent">
      <article
        dangerouslySetInnerHTML={ innerHTML }
      />
    </div>
  )
}

PreviewContent.propTypes = {
  content: PropTypes.string,
  isLoading: PropTypes.bool,
}
