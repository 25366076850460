import React, {
  useEffect,
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import IntegrationPartnerContext from './integrationPartnerContext'

import useIntegrationPartnerPlanQuery from './hooks/useIntegrationPartnerPlanQuery'

// Obviously, we're going to have to do more work to support additional
// providers, should they come. For now, KISS.
const SIGN_IN_PATH = '/integrations/duda/api/v1/sso/sign_in'


export default function IntegrationPartnerProvider({ children, domain = '', isIntegrationPartner }) {
  const { planDetails } = useIntegrationPartnerPlanQuery({
    enabled: isIntegrationPartner,
  })

  useEffect(() => {
    if ( !isIntegrationPartner || !planDetails ) {
      return
    }

    const script = document.createElement('script')

    script.src = planDetails.sdk_url

    document.head.appendChild(script)

    return () => {
      script.remove()
    }
  }, [isIntegrationPartner, planDetails])

  const value = useMemo(() => {
    const signInURL = `${ domain }${ SIGN_IN_PATH }`

    return {
      refresh() {
        if ( !window._dAPI ) {
          console.error('window._dAPI is not defined')
          return
        }

        window._dAPI.refresh()
      },

      isIntegrationPartner,
      signInURL,

      upgrade() {
        if ( !window._dAPI ) {
          console.error('window._dAPI is not defined')
          return
        }

        console.debug('### Calling window._dAPI.upgrade(%o)', {
          appId: planDetails.app_id,
          plansList: planDetails.duda_plan_ids,
          type: 'upgradeApp',
        })

        window._dAPI.upgrade({
          appId: planDetails.app_id,
          plansList: planDetails.duda_plan_ids,
          type: 'upgradeApp',
        })
      },
    }
  }, [domain, isIntegrationPartner, planDetails])

  return (
    <IntegrationPartnerContext.Provider
      value={ value }
    >
      { children }
    </IntegrationPartnerContext.Provider>
  )
}

IntegrationPartnerProvider.propTypes = {
  children: PropTypes.node,
  domain: PropTypes.string,
  isIntegrationPartner: PropTypes.bool,
}
