import { defineMessages } from 'react-intl'


export default defineMessages({
  consentPreferences: {
    defaultMessage: 'Consent Preferences',
    id: 'footer.consent-preferences',
  },

  doNotSell: {
    defaultMessage: 'Do not sell or share my personal information',
    id: 'footer.do-not-sell',
  },

  limitUse: {
    defaultMessage: 'Limit the use of my sensitive personal information',
    id: 'footer.limit-use',
  },

  ourCookiePolicy: {
    defaultMessage: 'Cookie Policy',
    id: 'footer.cookie-policy',
  },

  ourDisclaimer: {
    defaultMessage: 'Disclaimer',
    id: 'footer.disclaimer',
  },

  ourPrivacyPolicy: {
    defaultMessage: 'Privacy Policy',
    id: 'footer.privacy-policy',
  },

  ourTOU: {
    defaultMessage: 'Terms Of Use',
    id: 'footer.terms-of-use',
  },

  support: {
    defaultMessage: 'Support',
    id: 'footer.support',
  },
})
