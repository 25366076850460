import EventAPI from 'utils/event-api'

import getConsentedCategories from './getConsentedCategories'
import getWhitelist from './getWhitelist'


export default function triggerGetUpdatedCookieWhitelistByTermly(args) {
  const {
    consentMode,
    consentState,
    cookieConsent,
    cookies,
    visitorId,
  } = args

  const categories = getConsentedCategories({
    consentMode,
    cookieConsent,
  })

  const cookieWhitelist = getWhitelist(cookieConsent, cookies)

  EventAPI.dispatch('consent', {
    categories,
    consentState,
    cookies: cookieWhitelist,
    uuid: visitorId,
  })
}

