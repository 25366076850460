import Termly from 'termly-namespace'

import EventAPI from 'utils/event-api'

import CookieConsents from './cookieConsents'


if ( window.TERMLY_RESOURCE_BLOCKER_LOADED ) {
  throw new Error('Termly resource blocker has already been loaded on this page. ' +
                  `Check your markup for duplicate <script> tags including ${ document.currentScript.src }`)
}

window.TERMLY_RESOURCE_BLOCKER_LOADED = true

if ( !window.Termly ) {
  window.Termly = {}
}

window.Termly.getConsentState = () => {
  return CookieConsents.getConsentSettings() || Termly.getDefaultConsents()
}

window.Termly.on = EventAPI.on
window.Termly.off = EventAPI.off

if ( window.getUpdatedCookieWhitelistByTermly ) {
  EventAPI.on('consent', window.getUpdatedCookieWhitelistByTermly)
}
