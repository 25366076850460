// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-a8fc9c{display:flex;height:100%;justify-content:space-between;align-items:center;height:3.75rem;column-gap:1.5625rem}.termly-styles-leftContent-c15413{width:15.125rem;display:flex}.termly-styles-logo-f3c9eb{height:1.875rem;width:auto;margin-right:1rem}.termly-styles-middleContent-e50bb7{flex:1;padding-left:2.5625rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/Hat/styles.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,WAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CACA,oBAAA,CAGF,kCAME,eAAA,CACA,YAAA,CAGF,2BACE,eAAA,CACA,UAAA,CACA,iBAAA,CAGF,oCACE,MAAA,CACA,sBAAA","sourcesContent":[".root {\n  display: flex;\n  height: 100%;\n  justify-content: space-between;\n  align-items: center;\n  height: 3.75rem;\n  column-gap: 1.5625rem;\n}\n\n.leftContent {\n  // TODO: these values will be carried over once we get the parent layout component\n  // (the overall page layout) genericized and brought over into @termly_web/common\n  // width: $sidebar-width - $outer-vertical-margin + $sidebar-margin-right;\n  // We'll likely be using CSS variables.\n  // For now, this value suffices for the builder header.\n  width: 15.125rem;\n  display: flex;\n}\n\n.logo {\n  height: 1.875rem;\n  width: auto;\n  margin-right: 1rem;\n}\n\n.middleContent {\n  flex: 1;\n  padding-left: 2.5625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-a8fc9c",
	"leftContent": "termly-styles-leftContent-c15413",
	"logo": "termly-styles-logo-f3c9eb",
	"middleContent": "termly-styles-middleContent-e50bb7"
};
export default ___CSS_LOADER_EXPORT___;
