// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-f3af38 .termly-styles-dropdownIndicator-b09793{transition:transform .2s ease !important}.termly-styles-root-f3af38 .termly-styles-dropdownIndicator-b09793.termly-styles-isOpen-e52f21{transform:rotate(180deg)}.termly-styles-root-f3af38 .termly-styles-option-a85868{color:#000}.termly-styles-root-f3af38 .termly-styles-option-a85868.termly-styles-isDisabled-a9585b{opacity:.4}.termly-styles-root-f3af38 .termly-styles-option-a85868.termly-styles-isFocused-af72f8,.termly-styles-root-f3af38 .termly-styles-option-a85868.termly-styles-isSelected-c7be1b{background-color:#f5f6fa}.termly-styles-root-f3af38 .termly-styles-option-a85868:active:not(.termly-styles-isDisabled-a9585b){background-color:#b2d4ff}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/Select/styles.scss"],"names":[],"mappings":"AAOE,mEACE,wCAAA,CAEA,+FACE,wBAAA,CAIJ,wDACE,UAAA,CAEA,wFACE,UAAA,CAGF,+KAGE,wBAAA,CAGF,qGACE,wBAAA","sourcesContent":["// Normally, I don't like to nest rules, but the alternative is either\n// to use `!important` or to make sure that the consumers of this\n// component have their Emotion cache set to prepend <style>s to the <head>\n// rather than append them--neither of which give me the warm-and-fuzzies.\n// See https://github.com/JedWatson/react-select/issues/4953#issuecomment-991059178\n//\n.root {\n  .dropdownIndicator {\n    transition: transform .2s ease !important;\n\n    &.isOpen {\n      transform: rotate(180deg);\n    }\n  }\n\n  .option {\n    color: #000;\n\n    &.isDisabled {\n      opacity: 0.4;\n    }\n\n    &.isFocused,\n    &.isSelected\n    {\n      background-color: #f5f6fa;\n    }\n\n    &:active:not(.isDisabled) {\n      background-color: #b2d4ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-f3af38",
	"dropdownIndicator": "termly-styles-dropdownIndicator-b09793",
	"isOpen": "termly-styles-isOpen-e52f21",
	"option": "termly-styles-option-a85868",
	"isDisabled": "termly-styles-isDisabled-a9585b",
	"isFocused": "termly-styles-isFocused-af72f8",
	"isSelected": "termly-styles-isSelected-c7be1b"
};
export default ___CSS_LOADER_EXPORT___;
