// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-f77920{height:100%;display:flex;flex-direction:column;padding:40px;align-items:stretch;background-color:#f5f6fa;font-family:Roboto}.termly-styles-container-d5bb24{display:flex;flex-direction:column;align-items:center;margin-top:140px}.termly-styles-logo-a20b4b{height:35px;width:35px}.termly-styles-title-eb294d{color:#013986;font-weight:500;font-size:24px;margin:0}.termly-styles-abstractArt-c6085c{height:160px;width:160px;margin-bottom:28px}.termly-styles-bodyText-a36e56{color:#6c7794;max-width:20em;max-width:17em;text-align:center;line-height:1.375}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/GoneFishin/styles.scss"],"names":[],"mappings":"AAKA,2BACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,mBAAA,CACA,wBAXe,CAYf,kBAAA,CAGF,gCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,gBAAA,CAGF,2BACE,WAAA,CACA,UAAA,CAGF,4BACE,aA3BW,CA4BX,eAAA,CACA,cAAA,CACA,QAAA,CAGF,kCACE,YAAA,CACA,WAAA,CACA,kBAAA,CAGF,+BACE,aAvCU,CAwCV,cAAA,CACA,cAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":["$pale-grey-nine: #f5f6fa;\n$jeans-blue: #013986;\n$steel-two: #6c7794;\n\n\n.root {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: 40px;\n  align-items: stretch;\n  background-color: $pale-grey-nine;\n  font-family: Roboto;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 140px;\n}\n\n.logo {\n  height: 35px;\n  width: 35px;\n}\n\n.title {\n  color: $jeans-blue;\n  font-weight: 500;\n  font-size: 24px;\n  margin: 0;\n}\n\n.abstractArt {\n  height: 160px;\n  width: 160px;\n  margin-bottom: 28px;\n}\n\n.bodyText {\n  color: $steel-two;\n  max-width: 20em;\n  max-width: 17em;\n  text-align: center;\n  line-height: 1.375 \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-f77920",
	"container": "termly-styles-container-d5bb24",
	"logo": "termly-styles-logo-a20b4b",
	"title": "termly-styles-title-eb294d",
	"abstractArt": "termly-styles-abstractArt-c6085c",
	"bodyText": "termly-styles-bodyText-a36e56"
};
export default ___CSS_LOADER_EXPORT___;
