import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import IntercomAdaptorContext from './IntercomAdaptorContext'

import useIntercom from './hooks/useIntercom'


export default function IntercomAdaptor({ children }) {
  const intercom = useIntercom()

  const value = useMemo(() => ({
    signIn({ hasWebsite, user }) {
      if ( !user?.uuid ) {
        return
      }

      // TODO: This plan_name check should really be done using `usePlansQuery()`
      // or `useCurrentUserPlansQuery()` that is currently found only in dashboard
      const hasUpgraded = ( user.plan_name !== 'basic' && !user.is_trial_user )

      intercom.update({
        /* eslint-disable camelcase */
        created_at: user.created_at,
        email: user.email,
        user_id: user.uuid,
        /* eslint-enable */

        customAttributes: {
          hasUpgraded,
          hasWebsite: Boolean(hasWebsite),
          userSignUpDate: user.created_at,
        },
      })
    },

    signOut() {
      intercom.hardShutdown()
    },

    trackEvent(event) {
      intercom.trackEvent(event)
    },

    updateUser(customAttributes) {
      intercom.update({
        customAttributes,
      })
    },
  }), [intercom])

  return (
    <IntercomAdaptorContext.Provider
      value={ value }
    >
      { children }
    </IntercomAdaptorContext.Provider>
  )
}

IntercomAdaptor.propTypes = {
  children: PropTypes.node,
}

function makeName(user) {
  return [user.first_name, user.last_name]
    .filter(Boolean)
    .join(' ')
}
