import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import { navigateToPricingPage } from '@termly_web/common'

import UpgradeProviderContext from './upgradeContext'


export default function UpgradeProvider({ children, handleUpgrade, isUpgradable }) {
  const value = useMemo(() => ({
    isUpgradable,
    upgrade: handleUpgrade || navigateToPricingPage,
  }), [handleUpgrade, isUpgradable])

  return (
    <UpgradeProviderContext.Provider
      value={ value }
    >
      { children }
    </UpgradeProviderContext.Provider>
  )
}

UpgradeProvider.propTypes = {
  children: PropTypes.node,
  handleUpgrade: PropTypes.func,
  isUpgradable: PropTypes.bool,
}
