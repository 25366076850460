// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-ab023e{height:100%;display:flex;justify-content:center;align-items:center}.termly-styles-sizer-ed8842{height:5rem;width:5rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/Loading/styles.scss"],"names":[],"mappings":"AAAA,2BACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,4BACE,WAAA,CACA,UAAA","sourcesContent":[".root {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.sizer {\n  height: 5rem;\n  width: 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-ab023e",
	"sizer": "termly-styles-sizer-ed8842"
};
export default ___CSS_LOADER_EXPORT___;
