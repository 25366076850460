import React from 'react'
import PropTypes from 'prop-types'

import useWebsiteQuery from '../../hooks/useWebsiteQuery'

import ReactQueryStateHandler from '../ReactQueryStateHandler'

import WebsitePolicyContext from './WebsitePolicyContext'

import useWebsitePolicyQuery from './hooks/useWebsitePolicyQuery'

export default function WebsitePolicyProvider({ children, websiteID }) {
  const {
    isError: isWebsitePolicyError,
    isLoading: isWebsitePolicyLoading,
    websitePolicy,
  } = useWebsitePolicyQuery({
    websiteID,
  })

  const {
    isError: isWebsiteQueryError,
    isLoading: isWebsiteQueryLoading,
    website,
  } = useWebsiteQuery({
    websiteID,
  })

  const isError = ( isWebsitePolicyError || isWebsiteQueryError )
  const isLoading = ( isWebsitePolicyLoading || isWebsiteQueryLoading )

  if ( isError || isLoading ) {
    return (  
      <ReactQueryStateHandler
        isError={ isError }
        isLoading={ isLoading }
      />
    )
  }

  return (
    <WebsitePolicyContext.Provider
      value={{
        website,
        websitePolicy,
      }}
    >
      { children }
    </WebsitePolicyContext.Provider>
  )
}

WebsitePolicyProvider.propTypes = {
  children: PropTypes.node,
  websiteID: PropTypes.string,
}
