import filterConsents from '../filterConsents'

import convertTermlyConsentsToGTM from './convertTermlyConsentsToGTM'
import makeConsentDelta from './makeConsentDelta'
import makeUserPrefUpdateEvent from './makeUserPrefUpdateEvent'

const GOOGLE_TAG_DEVELOPER_ID = 'dNzg2MD'

if ( !window.dataLayer ) {
  window.dataLayer = []
}

/**
 * @name GTM
 */
export default {
  // Because updates to state can happen incrementally, and there is
  // no extant API for determining the current consent state, we have
  // to synthesize our own. Of course, this means that if someone,
  // somwhere, bypasses this API and starts pushing stuff onto
  // `window.dataLayer`, we're going to have an incomplete state
  // here...so I'm crossing my fingers and hoping that this won't
  // happen. Otherwise, I'm going to have to run through `dataLayer`
  // and re-build the final consent state _every time_ we call #send()
  // here. That's also problematic, particularly if Google truncates
  // that array at any point. Without a formal, published API, this is
  // the best we can do.
  _currentConsent: {},

  initCMP(defaults) {
    this.gtag('send', `developer_id.${ GOOGLE_TAG_DEVELOPER_ID }`, true)
  },

  // TODO: It's entirely unclear whether we need both gtag() and push() here,
  // but since my current task does not involve communicating with our
  // GTM template, I'm going to leave push() in place.

  /**
   * Pushes the supplied arguments into the data layer as an array
   *
   * @param {...*} args
   *
   * @see https://developers.google.com/tag-platform/devguides/gtag-integration#create-integration
   */
  gtag() {
    if ( window.TERMLY_FORCE_DISABLE_GCM ) {
      return
    }

    // N.B.: we can't use "rest arguments" (...args) here, because the
    // Google gtag code refuses to process what we push in. This despite
    // the fact that `arguments` is a deprecated language feature.
    window.dataLayer.push(arguments)
  },

  /**
   * Pushes the supplied object into the dataLayer
   *
   * @param {Object} config
   */
  push(config) {
    if ( window.TERMLY_FORCE_DISABLE_GCM ) {
      return
    }

    window.dataLayer.push(config)
  },

  /**
   * Sends the consents to GTM.
   *
   * @param {Object.<String, Boolean>} consents
   */
  send(consents) {
    const filteredConsents = filterConsents(consents)

    const gtmConsents = convertTermlyConsentsToGTM(filteredConsents)
    const consentDelta = makeConsentDelta(this._currentConsent, gtmConsents)

    if ( consentDelta ) {
      this.gtag('consent', 'update', consentDelta)

      Object.assign(this._currentConsent, consentDelta)
    }

    this.push(makeUserPrefUpdateEvent(filteredConsents))

    this.push({
      event: 'Termly.consentSaveDone',
    })
  },

  /**
   * Note: No conversion from Termly to GCM consents is performed.
   *
   * @param {Object <String, (Boolean|String)>} gcmConsents
   */
  sendConsentDefault(gcmConsents) {
    this.gtag('consent', 'default', gcmConsents)

    this._currentConsent = {
      ...gcmConsents,
    }
  },
}
