import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button } from '@termly/react-components'
import { Portal } from '@termly_web/common'

import PreviewContent from './PreviewContent'

import Styles from './styles.scss'


export default function PreviewOverlay({ content, handleClose, isLoading }) {
  const previewer = useRef(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    if ( previewer.current ) {
      previewer.current.focus()
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [previewer])

  const onKeyUp = useCallback((e) => {
    if ( e.key !== 'Escape' ) {
      return
    }

    e.stopPropagation()

    handleClose()
  }, [handleClose])

  const contentClassName = classnames(Styles.content, 't-previewContent')
  const rootClassName = classnames(Styles.root, 't-root')

  return (
    <Portal>
      <div
        className={ rootClassName }
        onContextMenu={ preventEvent }
        onCopy={ preventEvent }
        onCut={ preventEvent }
        onKeyUp={ onKeyUp }
        ref={ previewer }
        tabIndex="0"
      >
        <section className={ Styles.modal }>
          <header className={ Styles.header }>
            <Button
              className="t-closePreviewButton"
              color="primary"
              handleClick={ handleClose }
              type="outline"
            >
              Close
            </Button>
          </header>

          <div className={ contentClassName }>
            <PreviewContent
              content={ content }
              isLoading={ isLoading }
            />
          </div>
        </section>
      </div>
    </Portal>
  )
}

PreviewOverlay.propTypes = {
  handleClose: PropTypes.func.isRequired,

  content: PreviewContent.propTypes.content,
  isLoading: PreviewContent.propTypes.isLoading,
}

function preventEvent(e) {
  e.preventDefault()
}

