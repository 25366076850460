// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-e8eaa8{font-size:1.5rem;font-weight:500;line-height:1.333;margin:0 0 1rem;color:#000}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/PageTitle/styles.scss"],"names":[],"mappings":"AAAA,2BACE,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,UAAA","sourcesContent":[".root {\n  font-size: 1.5rem;\n  font-weight: 500;\n  line-height: 1.333;\n  margin: 0 0 1rem;\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-e8eaa8"
};
export default ___CSS_LOADER_EXPORT___;
