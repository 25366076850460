import React, {
  useCallback,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import Previewer from '../Previewer'

import PreviewContent from './PreviewContent'
import PreviewOverlay from './PreviewOverlay'

import useDocumentPreviewQuery from './hooks/useDocumentPreviewQuery'


export const PREVIEW_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

export default function DocumentPreviewer(props) {
  const {
    buttonOnly,
    document,
    handleOpenOverlay,
    isFetchEnabled,
    previewStatus,
  } = props

  const [showOverlay, setShowOverlay] = useState(false)

  const handleClose = useCallback(() => {
    setShowOverlay(false)
  }, [setShowOverlay])

  const handleShow = useCallback(() => {
    handleOpenOverlay()
    setShowOverlay(true)
  }, [handleOpenOverlay, setShowOverlay])

  const enabled = Boolean(isFetchEnabled && showOverlay)

  const {
    content,
    isLoading,
  } = useDocumentPreviewQuery({
    documentID: document.id,
    status: previewStatus,
  }, {
    enabled,
  })

  return (
    <>
      <Previewer
        handlePreview={ handleShow }
      >
        {
          renderPreviewContent({
            document,
            show: !buttonOnly,
          })
        }
      </Previewer>

      {
        renderOverlay({
          content,
          handleClose,
          isLoading,
          showOverlay,
        })
      }
    </>
  )
}

DocumentPreviewer.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  previewStatus: PropTypes.oneOf(
    Object.values(PREVIEW_STATUSES)
  ).isRequired,

  buttonOnly: PropTypes.bool,
  handleOpenOverlay: PropTypes.func,
  isFetchEnabled: PropTypes.bool,
}

DocumentPreviewer.defaultProps = {
  handleOpenOverlay: () => void 0,
  isFetchEnabled: true,
}

function renderOverlay({ content, showOverlay, handleClose, isLoading }) {
  if ( !showOverlay ) {
    return null
  }

  return (
    <PreviewOverlay
      content={ content }
      handleClose={ handleClose }
      isLoading={ isLoading }
    />
  )
}

function renderPreviewContent({ document, show }) {
  if ( !show ) {
    return null
  }

  return (
    <PreviewContent
      document={ document }
    />
  )
}
