// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-ee2d31{width:100%;background:#fff}@keyframes termly-styles-wave-aa28ba{0%{background-position:-41rem 0}100%{background-position:41rem 0}}.termly-styles-paragraph-cf8268,.termly-styles-heading-f8eb34{height:.9375rem;border-radius:.1875rem;background:linear-gradient(to right, rgba(226, 226, 226, 0.5) 0, rgba(226, 226, 226, 0.2) 35%, rgba(226, 226, 226, 0.5) 70%);animation:termly-styles-wave-aa28ba 2s linear infinite forwards}.termly-styles-heading-f8eb34{width:35%;margin:1.875rem 0 .625rem}.termly-styles-paragraph-cf8268{width:100%;margin:.3125rem 0}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/DocumentPreviewer/PreviewOverlay/PreviewContent/Placeholder/styles.scss"],"names":[],"mappings":"AAGA,2BACE,UAAA,CACA,eAAA,CAEA,qCACE,GACE,4BAAA,CAEF,KACE,2BAAA,CAAA,CAKN,8DACE,eAAA,CACA,sBAAA,CACA,4HAAA,CAIA,+DAAA,CAGF,8BAGE,SAAA,CACA,yBAAA,CAGF,gCAGE,UAAA,CACA,iBAAA","sourcesContent":["@import '../../mixins';\n\n\n.root {\n  width: 100%;\n  background: #fff;\n\n  @keyframes wave {\n    0% {\n      background-position: -41rem 0;\n    }\n    100% {\n      background-position: 41rem 0;\n    }\n  }\n}\n\n%fake-style {\n  height: 0.9375rem;\n  border-radius: 0.1875rem;\n  background: linear-gradient(to right,\n                              rgba(#e2e2e2, .5) 0,\n                              rgba(#e2e2e2, .2) 35%,\n                              rgba(#e2e2e2, .5) 70%);\n  animation: wave 2s linear infinite forwards;\n}\n\n.heading {\n  @extend %fake-style;\n\n  width: 35%;\n  margin: 1.875rem 0 0.625rem;\n}\n\n.paragraph {\n  @extend %fake-style;\n\n  width: 100%;\n  margin: 0.3125rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-ee2d31",
	"paragraph": "termly-styles-paragraph-cf8268",
	"heading": "termly-styles-heading-f8eb34",
	"wave": "termly-styles-wave-aa28ba"
};
export default ___CSS_LOADER_EXPORT___;
