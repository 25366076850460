import React from 'react'
import PropTypes from 'prop-types'

import Styles from './styles.scss'

const CONFIG = {
  'error': Styles.error,
  'light': Styles.light,
  'success': Styles.success,
}


export default function MessageBox({ children, theme = 'error' }) {
  if ( !children ) {
    return null
  }

  const rootClassName = CONFIG[theme]

  return (
    <div className={ rootClassName }>
      { children }
    </div>
  )
}

MessageBox.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(Object.keys(CONFIG)),
}
