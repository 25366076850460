export default function getStylesFromDocument(document) {
  if ( !document ) {
    return {}
  }

  const theme = getTheme(document)

  const elementsCSS = theme?.data?.elements_css || []

  return elementsCSS.reduce((cssMap, { name, style }) => ({
    ...cssMap,
    [name]: style,
  }), {})
}

function getTheme({ theme_id: themeID, themes }) {
  if ( !themeID ) {
    return themes.default_theme
  }

  const customTheme = themes.customize_themes.find(({ id }) => id === themeID)

  // This is a really odd edge case, but we have seen `elements_css` come back
  // null in production [TER-14098]
  if ( !customTheme?.data?.elements_css ) {
    console.warn('No elements_css found for custom theme. Returning default theme')
    return themes.default_theme
  }

  return customTheme
}
