import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Styles from './styles.scss'


// TODO: There are a lot of places all over the site where we use AutoFormattedMessage
// for the page title. Those cases should be replaced with this component, but I'm not
// doing that now because I need this to be a precision fix for TER-9026 (I went down
// that road and it got too hairy too fast for the amount of time I now have).


export default function PageTitle({ className, children }) {
  const rootClassName = classnames(Styles.root, className)

  return (
    <h1 className={ rootClassName }>
      { children }
    </h1>
  )
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,

  className: PropTypes.string,
}
