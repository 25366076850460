import React, {
  useMemo,
  useRef,
} from 'react'

import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import URLBuilder from 'lib/URLBuilder'

import SmartLink from '../SmartLink'

import Locale from './locale'

import Styles from './styles.scss'

const GLOBAL_LINKS = [
  {
    url: 'https://termly.io/our-privacy-policy/',
    text: Locale.ourPrivacyPolicy,
  },
  {
    url: 'https://termly.io/our-terms-of-use/',
    text: Locale.ourTOU,
  },
  {
    url: 'https://termly.io/our-disclaimer/',
    text: Locale.ourDisclaimer,
  },
  {
    url: 'https://termly.io/our-cookie-policy/',
    text: Locale.ourCookiePolicy,
  },
]

const TERMLY_DSAR_FORM_LINK = 'https://app.termly.io/notify/42ad146d-177d-4c95-a4a6-34f2a35f16b5'

const SIGNED_IN_LINKS = [
  {
    url: URLBuilder.supportURL(),
    text: Locale.support,
  },
  {
    url: TERMLY_DSAR_FORM_LINK,
    text: Locale.limitUse,
  },
  {
    url: TERMLY_DSAR_FORM_LINK,
    text: Locale.doNotSell,
  },
]


export default function Footer({ isGuest = false }) {
  const filteredLinks = useMemo(() => {
    return filterLinks(isGuest)
  }, [isGuest])

  return (
    <footer className={ Styles.root }>
      <ul className={ Styles.links }>
        {
          filteredLinks.map(({ url, text }) => (
            <li key={ text.id }>
              <SmartLink
                className={ Styles.link }
                showIcon={ false }
                url={ url }
              >
                <FormattedMessage
                  { ...text }
                />
              </SmartLink>
            </li>
          ))
        }
        {
          renderConsentPreferencesLink()
        }
      </ul>
    </footer>
  )
}

Footer.propTypes = {
  isGuest: PropTypes.bool,
}

function filterLinks(isGuest) {
  if ( isGuest ) {
    return GLOBAL_LINKS
  }

  return [...GLOBAL_LINKS, ...SIGNED_IN_LINKS]
}

function renderConsentPreferencesLink() {
  const loggedMessage = useRef()

  if ( !window.displayPreferenceModal ) {
    if ( !loggedMessage.current ) {
      console.debug('Not rendering the Consent Preferences footer link: window.displayPreferenceModal() was not found')
      loggedMessage.current = true
    }

    return null
  }

  const className = `${ Styles.link } t-displayPreferencesLink`

  return (
    <li>
      <Button
        className={ className }
        handleClick={ window.displayPreferenceModal }
        type="text"
      >
        <FormattedMessage
          { ...Locale.consentPreferences }
        />
      </Button>
    </li>
  )
}
