const PRICING_PAGE_URL = 'https://termly.io/pricing'
const STAGING_PRICING_PAGE_URL = 'https://staging.termly.io/pricing/'
const LOCAL_PRICING_PAGE_URL = 'https://staging.termly.io/pricing-dev'

const URL_CONFIG = {
  production: PRICING_PAGE_URL,
  staging: STAGING_PRICING_PAGE_URL,
  _other: LOCAL_PRICING_PAGE_URL,
}

const DESTINATION_URL = URL_CONFIG[process.env.NODE_ENV] || URL_CONFIG._other


export default function navigateToPricingPage({ queryParam } = {}) {
  const redirectURL = new URL(DESTINATION_URL)

  if ( queryParam ) {
    redirectURL.searchParams.set('upgrade', queryParam)
  }

  window.location.assign(redirectURL.toString())
}
