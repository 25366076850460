// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-text-e9a86d{color:#363840;font-size:.875rem;font-weight:500;line-height:1;margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/Label/styles.scss"],"names":[],"mappings":"AAAA,2BACE,aAAA,CACA,iBAAA,CACA,eAAA,CACA,aAAA,CACA,mBAAA","sourcesContent":[".text {\n  color: #363840;\n  font-size: 0.875rem;\n  font-weight: 500;\n  line-height: 1;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "termly-styles-text-e9a86d"
};
export default ___CSS_LOADER_EXPORT___;
