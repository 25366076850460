// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-root-a23abe{border-top:1px solid var(--common-footer-top-border-color, #ced4da);padding-top:.625rem;display:flex;justify-content:var(--common-footer-justify-content, normal)}.termly-styles-links-c9a7f2{list-style-type:none;margin:0;padding:0;display:flex;flex-direction:row;justify-content:flex-start;flex-wrap:wrap}.termly-styles-links-c9a7f2 .termly-styles-link-b1bf11{font-size:.75rem;line-height:1rem;color:#6c7794;white-space:nowrap}.termly-styles-links-c9a7f2>*:not(:last-child){margin-right:1rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/Footer/styles.scss"],"names":[],"mappings":"AAAA,2BACE,mEAAA,CACA,mBAAA,CACA,YAAA,CAEA,4DAAA,CAGF,4BACE,oBAAA,CACA,QAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CAEA,uDACE,gBAAA,CACA,gBAAA,CACA,aAAA,CACA,kBAAA,CAIA,+CACE,iBAAA","sourcesContent":[".root {\n  border-top: 1px solid var(--common-footer-top-border-color, #ced4da);\n  padding-top: 0.625rem;\n  display: flex;\n\n  justify-content: var(--common-footer-justify-content, normal);\n}\n\n.links {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n\n  .link {\n    font-size: 0.75rem;\n    line-height: 1rem;\n    color: #6c7794;\n    white-space: nowrap;\n  }\n\n  & > * {\n    &:not(:last-child) {\n      margin-right: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "termly-styles-root-a23abe",
	"links": "termly-styles-links-c9a7f2",
	"link": "termly-styles-link-b1bf11"
};
export default ___CSS_LOADER_EXPORT___;
