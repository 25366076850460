import React, {
  useCallback,
} from 'react'

import PropTypes from 'prop-types'


export default function Form({ children, handleSubmit, isDisabled, ...formProps }) {
  const onSubmit = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    if ( isDisabled ) {
      return
    }

    handleSubmit()
  }, [handleSubmit, isDisabled])

  return (
    <form
      { ...formProps }
      onSubmit={ onSubmit }
    >
      { children }
    </form>
  )
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,

  isDisabled: PropTypes.bool,
  children: PropTypes.node,
}
