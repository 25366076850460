import React from 'react'

import Styles from './styles.scss'


export default function Placeholder() {
  return (
    <div className={ Styles.root }>
      <FakeSection />
      <FakeSection />
      <FakeSection />
      <FakeSection />
    </div>
  )
}

function FakeSection() {
  return (
    <>
      <div className={ Styles.heading } />
      <div className={ Styles.paragraph } />
      <div className={ Styles.paragraph } />
      <div className={ Styles.paragraph } />
    </>
  )
}
