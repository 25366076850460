// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".termly-styles-text-bdf096{white-space:nowrap}.termly-styles-button-b0e415{color:#00c999 !important;font-size:1rem !important;font-weight:700;text-transform:uppercase;margin-left:1rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-73c07e1441/1/client/common/src/components/BuildVersionChecker/RefreshPrompt/styles.scss"],"names":[],"mappings":"AAAA,2BACE,kBAAA,CAGF,6BACE,wBAAA,CACA,yBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".text {\n  white-space: nowrap;\n}\n\n.button {\n  color: #00c999 !important;\n  font-size: 1rem !important;\n  font-weight: 700;\n  text-transform: uppercase;\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "termly-styles-text-bdf096",
	"button": "termly-styles-button-b0e415"
};
export default ___CSS_LOADER_EXPORT___;
