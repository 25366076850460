// Credit where credit is due: https://stackoverflow.com/questions/49426474

import {
  useEffect,
  useState,
} from 'react'

import PropTypes from 'prop-types'

import { createPortal } from 'react-dom'

const CLASS_NAME = 'portal-root'
const TAG_NAME = 'div'


export default function Portal({ children }) {
  const [container] = useState(document.createElement(TAG_NAME))

  container.classList.add(CLASS_NAME)

  /* eslint-disable react-hooks/exhaustive-deps */
  // We only need this to happen at mount
  useEffect(() => {
    document.body.appendChild(container)

    return () => {
      document.body.removeChild(container)
    }
  }, [container])
  /* eslint-enable */

  return createPortal(children, container)
}

Portal.propTypes = {
  children: PropTypes.node,
}
