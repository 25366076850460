import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { Button } from '@termly/react-components'

import PreviewIcon from './img/preview-eye.svg'

import Locale from './locale'
import Styles from './styles.scss'


export default function Previewer({ children, handlePreview }) {
  const buttonClassName = classnames(Styles.button, 't-previewSwitch')

  return (
    <>
      { renderPreviewBox(children) }

      <Button
        className={ buttonClassName }
        color="primary"
        handleClick={ handlePreview }
        type="outline"
      >
        <img
          alt=""
          className={ Styles.previewButton__icon }
          src={ PreviewIcon }
        />
        <FormattedMessage
          { ...Locale.previewButton }
        />
      </Button>
    </>
  )
}

Previewer.propTypes = {
  handlePreview: PropTypes.func.isRequired,

  children: PropTypes.node,
  className: PropTypes.string,
}

function renderPreviewBox(children) {
  if ( !children ) {
    return null
  }

  return (
    <div className={ Styles.previewBox }>
      { children }
    </div>
  )
}
