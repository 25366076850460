import api from "!../../../../../../../style-loader-virtual-15bb14bb31/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-129f8d7124.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../css-loader-virtual-5d031101a5/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../sass-loader-virtual-a80a7d9b41/0/cache/sass-loader-npm-13.3.2-82bfeae746-3486134c88.zip/node_modules/sass-loader/dist/cjs.js!./styles.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};