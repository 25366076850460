import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import { getStylesFromDocument } from '@termly_web/common'

import Styles from './styles.scss'


export default function PreviewContent({ document }) {
  const style = useMemo(() => {
    const documentStyles = getStylesFromDocument(document)

    return {
      '--background': documentStyles.body.background,
      '--body-color': documentStyles.body_text.color,
      '--body-font-family': documentStyles.body_text.font_family,
      '--title-color': documentStyles.title.color,
      '--title-font-family': documentStyles.title.font_family,
    }
  }, [document])

  return (
    <div
      className={ Styles.root }
      style={ style }
    >
      <div className={ Styles.document }>
        <div className={ Styles.title }>
          { document.name }
        </div>

        <div className={ Styles.body }>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Phasellus vestibulum commodo lorem eget
            tincidunt. Curabitur imperdiet commodo risus sed
            commodo.
          </p>

          <p>
            Nullam auctor pellentesque pretium. Mauris blandit
            est sit amet odio tempus, sit amet viverra ipsum
            finibus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Donec bibendum
            congue ipsum quis ullamcorper. Ut risus lacus, faucibus sit
            amet cursus vitae, tristique id sapien.
          </p>

          <p>
            Vestibulum leo justo, mollis euismod nunc eget, feugiat
            vulputate enim.
          </p>

          <p>
            Suspendisse consectetur ac odio rhoncus commodo. In hac
            habitasse platea dictumst. In hac habitasse platea dictumst.
          </p>

          <p>
            Curabitur egestas rhoncus ante, a lobortis
            velit ultrices id. Integer blandit tincidunt luctus. Nam
            efficitur a sapien et placerat. Ut faucibus quis nulla
            dignissim mollis.
          </p>
        </div>
      </div>
    </div>
  )
}

PreviewContent.propTypes = {
  document: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}

