import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Loading } from '@termly/react-components'

import { MessageBox } from '@termly_web/common'

import Locale from './locale'
import Styles from './styles.scss'

const DEFAULT_ERROR_MESSAGE = Locale.errorMessage


export default function ReactQueryStateHandler(props) {
  const {
    errorMessage,
    isError,
    isLoading,
    isFetching,
    isRefetching,
  } = props

  const { formatMessage } = useIntl()

  if ( isError ) {
    const content = ( errorMessage ) ? errorMessage : DEFAULT_ERROR_MESSAGE

    return (
      <MessageBox
        content={ formatMessage(content) }
      />
    )
  }

  const showLoadingSpinner = ( isLoading || isFetching || isRefetching )

  if ( showLoadingSpinner ) {
    return (
      <div className={ Styles.loading }>
        <Loading />
      </div>
    )
  }

  return null
}

ReactQueryStateHandler.propTypes = {
  errorMessage: PropTypes.shape({
    defaultMessage: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRefetching: PropTypes.bool,
}
